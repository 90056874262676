import {Camera} from "@capacitor/camera";
import {ImagePickerCustomElements} from "./helpers/image-picker/ImagePickerCustomElements";
import {AbIAPClientStripe} from "./helpers/iap/AbIAPClientStripe";
import {StripeWebPlugin} from "./helpers/iap/StripeWebPlugin";
import {IAPManager} from "./helpers/iap/IAPManager";
import {A2UNativeBrowser} from "a2u-capacitor-native-browser-plugin";
import {MarketingPixelsPlugin} from "./helpers/marketing-pixels/MarketingPixelsPlugin";
import {FirebaseAnalytics} from "./helpers/Analytics/FirebaseAnalytics";
import {AuthPromoCodePlugin} from "./helpers/Auth/AuthPromoCodePlugin"
import {UsersLand} from './db/UsersLand.js';
import {ZodiacSign} from './db/ZodiacSign.js';
import {Compatibility} from './db/Compatibility.js';
import {Palm} from './db/Palm.js';
import {ToxicExercises} from './db/ToxicExercises.js';
import {Plans} from './db/Plans.js';
import {CustomizedActionPlans2} from './db/CustomizedActionPlans2.js';
import {CustomizedActionPlansSteps} from './db/CustomizedActionPlansSteps.js';
import {AstroAdvisers} from './db/AstroAdvisers.js';
import {ReviewsAstroAdvisors} from './db/ReviewsAstroAdvisors.js';
import {ToxicRelationshipUserAnswers} from './db/ToxicRelationshipUserAnswers.js';

export const moduleConfig = {
  plugins: {CapacitorCamera: Camera, ImagePickerCustomElements, AbIAPClient: AbIAPClientStripe, StripeWebPlugin, IAPManager, A2UNativeBrowser, MarketingPixelsPlugin, AnalyticsProvider: FirebaseAnalytics.getInstance(), AuthPromoCodePlugin},

  mixin: {
    async created() {
      
            const initImagePickerCustomElements = () => {
                try {
                    const plugin = this.plugins.ImagePickerCustomElements;

                    if (!plugin) {
                        return;
                    }

                    const instance = new plugin();
                    instance.init();
                } catch (e) {
                    console.error('ImagePickerCustomElements error:', e);
                }
            }; initImagePickerCustomElements();              
        
    },

    async beforeUnmount() {
      
    },

    methods: {

      // Init after a2u initialization
      async initModulesOnAppInit(a2u) {
        // Log the code version
        console.log(`Init modules on app init`, a2u?.getCodeVersion());

        
      },
    }
  },

  customDbModels: {
    'users_land': UsersLand,
'zodiac_sign': ZodiacSign,
'compatibility': Compatibility,
'palm': Palm,
'toxic_exercises': ToxicExercises,
'plans': Plans,
'customized_action_plans2': CustomizedActionPlans2,
'customized_action_plans_steps': CustomizedActionPlansSteps,
'astro_advisers': AstroAdvisers,
'reviews_astro_advisors': ReviewsAstroAdvisors,
'toxic_relationship_user_answers': ToxicRelationshipUserAnswers
  },
}
